<template>
  <div>
    <v-progress-circular
      v-if="loading"
      class="loading-spinner"
      indeterminate
      size="100"
      color="secondary"/>
    <div
      v-else
      class="catalog-repository">
      <div class="catalog-repository__content">
        <CyAlert
          theme="error"
          :content="errors"/>
        <CyWizardServiceCatalog
          hide-use-btns
          :has-create-stack-btn-visible="false"
          :catalog-repository-canonical="catalogRepositoryCanonical"/>
      </div>
      <div class="catalog-repository__sidebar">
        <div class="sidebar">
          <div
            v-if="catalogRepository"
            class="sidebar__section">
            <p class="sidebar__title">
              {{ $t('stacks.gitRepo') }}
            </p>
            <a
              v-if="hasRepoURL"
              :href="$sanitizeUrl(repoURL)"
              target="_blank"
              rel="noopener noreferrer"
              class="cy-link cy-link--external d-flex align-center">
              <v-icon
                size="16"
                color="primary"
                class="mr-1">
                {{ repoInfo.provider.icon }}
              </v-icon>
              <span class="truncate">{{ repoInfo.text }}</span>
            </a>
            <span
              v-else
              class="truncate">
              {{ catalogRepository.url }}
            </span>
          </div>
          <div
            v-if="_.get(catalogRepository, 'credential_canonical')"
            class="sidebar__section">
            <div class="sidebar__title">
              {{ $t('Credential') }}
            </div>
            <router-link
              :to="{
                name: 'credential',
                params: {
                  credentialCanonical: catalogRepository.credential_canonical,
                  backRouteTo: 'catalogRepositories',
                },
              }"
              class="cy-link d-flex align-center">
              <span class="truncate ml-1">{{ catalogRepository.credential_canonical }}</span>
            </router-link>
          </div>
          <CyWizardStackInsightsCard
            class="sidebar__section"/>
        </div>
      </div>
    </div>

    <CyModal
      v-if="refreshedRepository"
      modal-type="success"
      :cancel-btn-text="$t('forms.btnClose')"
      :header-title="`${$t('repoRefreshSuccess', { repo: refreshedRepository })}`"
      :cancel-btn-func="() => refreshedRepository = null"
      action-btn-hidden>
      <div v-if="repositoryStackChanges.length">
        <div class="d-flex justify-space-between">
          <div
            v-for="[typeOfChange, stacks] in repositoryStackChanges"
            :key="typeOfChange">
            <span class="font-weight-bold">
              {{ `${typeOfChange} ${_.toLower($tc('stackCount', stacks.length, { count: stacks.length }))}` }}
            </span>
            <CyTagList
              class="d-flex flex-column mt-2"
              :tags="stacks">
              <template #tag="{ tag }">
                <CyTag
                  :variant="stackVariant(typeOfChange)">
                  {{ tag.canonical }}
                </CyTag>
              </template>
            </CyTagList>
          </div>
        </div>
      </div>
      <p v-else>
        {{ $t('noStacksChanged') }}
      </p>
    </CyModal>

    <CyModal
      v-if="showDeleteModal"
      :header-title="$t('delete.header')"
      :action-btn-func="onDeleteConfirm"
      :cancel-btn-func="() => $toggle.showDeleteModal(false)"
      keep-open-on-action-click
      :loading="deleting"
      modal-type="delete"
      small>
      <p>{{ $t('delete.sentence') }}</p>
      <h3>{{ catalogRepository.name }}</h3>
      <p class="url">
        {{ catalogRepository.url }}
      </p>
      <p class="ma-0">
        {{ $t('delete.repository') }}
      </p>
    </CyModal>

    <portal to="header-actions">
      <div class="d-flex justify-end space-x-2">
        <CyWizardStackCloneMenu
          v-if="!loading"
          :catalog-repository="catalogRepository"/>

        <CyTooltip
          v-if="!$isCreationRoute"
          v-has-rights-to="['RefreshServiceCatalogSource', catalogRepositoryCanonical]"
          :max-width="240"
          left>
          <template #activator="{ on }">
            <CyButton
              :loading="refreshing"
              icon="refresh"
              data-cy="refresh-button"
              variant="secondary"
              theme="primary"
              v-on="on"
              @click="refreshCR">
              {{ $t('forms.btnRefresh') }}
            </CyButton>
          </template>
          {{ $t('refreshCRTooltip') }}
        </CyTooltip>

        <CyMenu
          v-if="!_.$isEmpty($static.menuItems)"
          bottom
          left
          offset-y
          :items="$static.menuItems">
          <template #activator="{ on, attrs }">
            <CyButton
              icon="more_horiz"
              theme="primary"
              variant="tertiary"
              aria-label="More actions"
              icon-only
              v-bind="attrs"
              v-on="on"/>
          </template>
        </CyMenu>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import CyTagList from '@/components/CyTagList.vue'
import CyWizardServiceCatalog from '@/components/CyWizardServiceCatalog.vue'
import CyWizardStackCloneMenu from '@/components/CyWizardStackCloneMenu.vue'
import CyWizardStackInsightsCard from '@/components/CyWizardStackInsightsCard.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageCatalogRepository',
  components: {
    CyWizardStackCloneMenu,
    CyWizardServiceCatalog,
    CyWizardStackInsightsCard,
    CyTagList,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.catalogRepository?.name, [
      {
        label: this.$t('routes.catalogRepositories'),
        name: 'catalogRepositories',
      },
      {
        label: this.$t('routes.stacksSection'),
        name: 'stacksSection',
      },
    ])
  },
  props: {
    catalogRepositoryCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    loading: true,
    showDeleteModal: false,
    deleting: false,
    refreshing: false,
    refreshedRepository: null,
  }),
  computed: {
    ...mapState('organization/catalogRepository', {
      catalogRepositoryErrors: (state) => state.errors.catalogRepository,
      refreshErrors: (state) => state.errors.refresh,
    }),
    ...mapGetters('organization/catalogRepository', [
      'catalogRepository',
    ]),
    $static () {
      const { catalogRepositoryCanonical } = this
      const { canDisplay } = this.$cycloid.permissions
      const backRouteTo = this.$route.name
      return {
        menuItems: [
          {
            icon: 'edit',
            label: this.$t('forms.btnEdit'),
            action: () => {
              this.$router.push({
                name: 'editCatalogRepository',
                params: { catalogRepositoryCanonical, backRouteTo },
              })
            },
            permissionKey: 'UpdateServiceCatalogSource',
          },
          {
            icon: 'delete',
            label: `${this.$t('forms.btnDelete')}...`,
            action: () => { this.showDeleteModal = true },
            permissionKey: 'DeleteServiceCatalogSource',
          },
        ].filter(({ permissionKey }) => !permissionKey || canDisplay(permissionKey, catalogRepositoryCanonical)),
      }
    },
    stackVariant () {
      return (typeOfChange) => ({
        added: 'secondary',
        updated: 'primary',
        deleted: 'default',
      }[typeOfChange])
    },
    errors () {
      const { refreshErrors, catalogRepositoryErrors } = this
      return _.filter([
        ...refreshErrors,
        ...catalogRepositoryErrors,
      ], ({ code }) => code !== 'Required')
    },
    hasRepoURL () {
      return !_.isEmpty(this.catalogRepository?._repoURL)
    },
    repoInfo () {
      if (!this.hasRepoURL || !this.catalogRepository) return ''
      return this.catalogRepository?._repoURL
    },
    repoURL () {
      return this.repoInfo?.getLink(this.stack?.directory)
    },
  },
  async mounted () {
    await this.GET_CATALOG_REPOSITORY({ catalogRepositoryCanonical: this.catalogRepositoryCanonical })
    this.loading = false
  },
  methods: {
    ...mapActions('organization/catalogRepository', [
      'GET_CATALOG_REPOSITORY',
      'DELETE_CATALOG_REPOSITORY',
      'REFRESH_CATALOG_REPOSITORY',
    ]),
    async onDeleteConfirm () {
      const { catalogRepository, $router } = this

      this.deleting = true
      await this.DELETE_CATALOG_REPOSITORY({ catalogRepository, $router })
      this.deleting = false
    },
    async refreshCR () {
      if (this.refreshing) return
      this.$toggle.refreshing(true)
      const { catalogRepository: { name }, catalogRepositoryCanonical: canonical } = this
      const res = await this.REFRESH_CATALOG_REPOSITORY({ canonical, name })
      if (res) {
        this.refreshedRepository = canonical
        this.repositoryStackChanges = Object.entries(res).filter(([_, changes]) => changes.length)
      }
      this.$toggle.refreshing(false)
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.catalogRepository',
        delete: {
          header: 'Delete catalog repository?',
          sentence: 'Are you sure that you want to delete this catalog repository?',
          repository: `Please note that this does not delete the associated git repository. If you want to do that as well, you'll have to do it manually afterwards.`,
        },
        noStacksChanged: 'No stack changes were found',
        refreshCRTooltip: 'For best performance, general stacks information is cached in database. Refreshing a catalog repository will update this cache.',
        repoRefreshSuccess: '{repo} was refreshed successfully',
      },
      es: {
        title: '@:routes.catalogRepository',
        delete: {
          header: 'Borrar repositorio del catálogo ?',
          sentence: 'Estás seguro de querer borrar este repositorio del catálogo?',
          repository: 'Ten en cuenta que esto no elimina el repositorio git asociado. Si quiere hacer eso también, tendrás que hacerlo manualmente después.',
        },
        noStacksChanged: 'No se encontraron cambios en el stack',
        refreshCRTooltip: 'Para un mejor rendimiento, la información general de los stacks se almacena en caché en la base de datos. Actualizar un repositorio de catálogo actualizará este caché.',
        repoRefreshSuccess: '{repo} se actualizó correctamente',
      },
      fr: {
        title: '@:routes.catalogRepository',
        delete: {
          header: 'Supprimer sources du catalogue ?',
          sentence: 'Êtes-vous sûr de vouloir supprimer ces sources du catalogue ?',
          repository: 'Veuillez noter que cela ne supprime pas le dépôt git associé. Si vous souhaitez le faire également, vous devrez le faire manuellement par la suite.',
        },
        noStacksChanged: `Aucun changement de stack n'a été trouvé`,
        refreshCRTooltip: `Pour de meilleures performances, les informations générales sur les stacks sont mises en cache dans la base de données. L'actualisation d'un dépot de catalogue mettra à jour ce cache.`,
        repoRefreshSuccess: '{repo} a été rafraîchie avec succès',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.url {
  color: cy-get-color("grey", "dark-2");
}

.catalog-repository {
  display: grid;
  grid-template-areas:
    "content"
    "sidebar";
  width: 100%;
  margin-top: -24px;

  &__content {
    display: flex;
    grid-area: content;
    flex-direction: column;
    padding: #{$spacer-6} #{$spacer-8} 64px;
  }

  &__sidebar {
    grid-area: sidebar;
    padding: #{$spacer-6} #{$spacer-8};

    @include breakpoint("xl") {
      border-left: solid 1px cy-get-color("grey", "light-2");
    }
  }

  .truncate {
    display: block;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include breakpoint("xl") {
    grid-template-areas: "content sidebar";
    grid-template-columns: minmax(0, 1fr) 464px;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;

  &__section + &__section {
    margin-top: $spacer-4;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: $spacer;
    font-weight: bold;
  }
}
</style>
